import './App.scss';

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    ApparelProps, LocationProps, NewsProps, ProvisionProps, RecipeProps, StoreProps
} from '../Interfaces/Props';
import Footer from './Components/Footer/Footer';
import InfoBar from './Components/InfoBar/InfoBar';
import ApparelItem from './Components/Items/ApparelItem';
import Article from './Components/Items/Article';
import Location from './Components/Items/Location';
import Provision from './Components/Items/Provision';
import Recipe from './Components/Items/Recipe';
import Nav from './Components/Nav/Nav';
import About from './Components/Pages/About/About';
import Apparel from './Components/Pages/Apparel/Apparel';
import Find from './Components/Pages/Find/Find';
import Home from './Components/Pages/Home/Home';
import News from './Components/Pages/News/News';
import Provisions from './Components/Pages/Provisions/Provisions';
import Recipes from './Components/Pages/Recipes/Recipes';
import Snipcart from './Components/Pages/Snipcart/Snipcart';
import PromotionsBar from './Components/PromotionBar/PromotionsBar';
import makeLink from './Components/Tools/LinkMaker';
import ScrollToTop from './Components/Tools/ScrollToTop';

const sanityClient = require("@sanity/client");

class App extends Component {
  state: {
    recipes: RecipeProps[];
    news: NewsProps[];
    provisions: ProvisionProps[];
    apparel: ApparelProps[];
    locations: LocationProps[];
    promotionalMessage: string | false;
    facebook: string;
    instagram: string;
    email: string;
    phone: string;
    loaded: boolean;
  };
  client: any;

  constructor(props: any) {
    super(props);
    this.state = {
      recipes: [],
      news: [],
      provisions: [],
      apparel: [],
      locations: [],
      promotionalMessage: false,
      facebook: "",
      instagram: "",
      email: "",
      phone: "",
      loaded: false
    };
    this.client = sanityClient({
      projectId: "xg0sb2b3",
      dataset: "website-data",
      useCdn: true
    });

    //get info
    this.client
      .fetch(`*[_type == "promotion"]{promotionalMessage}[0]`)
      .then((result: any) => {
        if (result)
          this.setState({ promotionalMessage: result.promotionalMessage });
        else this.setState({ promotionalMessage: false });
      });

    this.client
      .fetch(
        `*[_type == "contactInfo"]{facebookLink, instagramLink, emailAddress, phoneNumber}[0]`
      )
      .then((result: any) => {
        this.setState({
          facebook: result.facebookLink,
          instagram: result.instagramLink,
          email: result.emailAddress,
          phone: result.phoneNumber
        });
      });

    // get stores
    this.client
      .fetch(
        `*[_type == "store"]{storeName, storeAddress, storePhoneNumber, storeLink, storeLocation->{locationName}}`
      )
      .then((results: any) => {
        let locations: { [location: string]: LocationProps } = {};
        let keys: string[] = [];
        results.map((result: any) => {
          const store: StoreProps = {
            name: result.storeName ? result.storeName : "",
            address: result.storeAddress ? result.storeAddress : "",
            phone: result.storePhoneNumber ? result.storePhoneNumber : "",
            link: result.storeLink
          };
          if (
            result.storeLocation &&
            locations[result.storeLocation.locationName]
          )
            locations[result.storeLocation.locationName].stores.push(store);
          else if (result.storeLocation) {
            keys.push(result.storeLocation.locationName);
            locations[result.storeLocation.locationName] = {
              name: result.storeLocation.locationName,
              stores: [store]
            };
          }
          return null;
        });
        this.setState({
          locations: keys.map((key: string) => {
            return { name: key, stores: locations[key].stores };
          })
        });
      });

    // get recipes
    this.client
      .fetch(
        `*[_type == "recipe"]{recipeTitle, "recipePictureURLs": recipePictures[].asset->url, datePosted, recipeOverview, recipeIngredients, recipeInstructions, recipeTags}`
      )
      .then((results: any) =>
        this.setState({
          recipes: results.map((result: any) => {
            let recipe: RecipeProps = {
              title: result.recipeTitle ? result.recipeTitle : "",
              date: result.datePosted
                ? this.americanizeDate(result.datePosted)
                : "0-0-0",
              ingredients: result.recipeIngredients
                ? result.recipeIngredients
                : [],
              instructions: result.recipeInstructions
                ? result.recipeInstructions
                : []
            };
            if (result.recipePictureURLs && result.recipePictureURLs.length > 0)
              recipe = { ...recipe, images: result.recipePictureURLs };
            if (result.recipeOverview)
              recipe = { ...recipe, overview: result.recipeOverview };
            if (result.recipeTags)
              recipe = { ...recipe, tags: result.recipeTags };

            return recipe;
          })
        })
      );

    // get articles
    this.client
      .fetch(
        `*[_type == "newsArticle"]{title, "imageURLs": images[].asset->url, datePosted, content, articleListContent{listTitle, listContent}, articleLinks[]{"text": linkText, "url": linkURL}, articleTags}`
      )
      .then((results: any) => {
        this.setState({
          news: results.map((result: any) => {
            let article: NewsProps = {
              title: result.title ? result.title : "",
              date: result.datePosted
                ? this.americanizeDate(result.datePosted)
                : "0-0-0"
            };
            if (result.imageURLs && result.imageURLs.length > 0)
              article = { ...article, images: result.imageURLs };
            if (result.content)
              article = { ...article, content: result.content };
            if (result.articleListContent)
              article = { ...article, list: result.articleListContent };
            if (result.articleLinks)
              article = { ...article, links: result.articleLinks };
            if (result.tags) article = { ...article, tags: result.tags };

            return article;
          })
        });
      });

    //get provisions
    this.client
      .fetch(
        `*[_type == "provision"]{datePosted, provisionTitle,"provisionImageURLs": provisionImages[].asset->url, provisionDescription, provisionIngredients, provisionPurchaseOptions[]{option, optionPrice, optionWeight}, provisionNotes, provisionCategory->{provisionTypeListing{category, description, displayOrder}}, provisionTags}`
      )
      .then((results: any) =>
        this.setState({
          provisions: results.map((result: any) => {
            let provision: ProvisionProps = {
              title: result.provisionTitle ? result.provisionTitle : "",
              description: result.provisionDescription
                ? result.provisionDescription
                : [],
              new: false,
              category: result.provisionCategory.provisionTypeListing
                ? result.provisionCategory.provisionTypeListing
                : { category: "", description: "", displayOrder: 100000 },
              options: result.provisionPurchaseOptions
                ? result.provisionPurchaseOptions
                : [
                    {
                      optionPrice: 999999,
                      option: "not for sale",
                      optionWeight: 999999
                    }
                  ]
            };
            if (result.datePosted && this.newItem(result.datePosted))
              provision = { ...provision, new: true };
            if (result.provisionImageURLs)
              provision = { ...provision, images: result.provisionImageURLs };
            if (result.provisionIngredients)
              provision = {
                ...provision,
                ingredients: result.provisionIngredients
              };
            if (result.provisionNotes)
              provision = { ...provision, notes: result.provisionNotes };
            if (result.provisionTags)
              provision = { ...provision, tags: result.provisionTags };

            return provision;
          })
        })
      );

    // get apparel
    this.client
      .fetch(
        `*[_type == "apparel"]{datePosted, apparelTitle, "apparelImageURLs": apparelImages[].asset->url, apparelDescription, apparelPurchaseOptions[]{option, optionPrice, optionWeight}, apparelNotes, apparelCategory->{apparelTypeListing{category, description, displayOrder}}, apparelTags}`
      )
      .then((results: any) =>
        this.setState({
          apparel: results.map((result: any) => {
            let apparel: ApparelProps = {
              title: result.apparelTitle ? result.apparelTitle : "",
              new: false,
              description: result.apparelDescription
                ? result.apparelDescription
                : [],
              options: result.apparelPurchaseOptions
                ? result.apparelPurchaseOptions
                : [
                    {
                      optionPrice: 999999,
                      option: "not for sale",
                      optionWeight: 999999
                    }
                  ],
              category: result.apparelCategory.apparelTypeListing
                ? result.apparelCategory.apparelTypeListing
                : { category: "", description: "", displayOrder: 100000 }
            };
            if (result.datePosted && this.newItem(result.datePosted))
              apparel = { ...apparel, new: true };
            if (result.apparelImageURLs)
              apparel = { ...apparel, images: result.apparelImageURLs };
            if (result.apparelNotes)
              apparel = { ...apparel, notes: result.apparelNotes };
            if (result.tags) apparel = { ...apparel, tags: result.apparelTags };

            return apparel;
          })
        })
      );
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  newItem(date: string) {
    let posted = new Date(date);
    let now = new Date();
    return now.getTime() - posted.getTime() < 1000 * 3600 * 24 * 30;
  }

  americanizeDate(date: string) {
    let splitDate = date.split("-");
    return [splitDate[1], splitDate[2], splitDate[0]].join("/");
  }

  render() {
    let items: any[] = [];

    this.state.provisions.map(
      (provision: ProvisionProps, j: number) =>
        (items = items.concat(
          provision.options.map(
            (
              option: {
                option: string;
                optionPrice: number;
                optionWeight: number;
              },
              i: number
            ) => (
              <button
                key={`provision${i}${j}`}
                className="snipcart-add-item"
                data-item-id={provision.title + " " + option.option}
                data-item-name={provision.title + " " + option.option}
                data-item-price={option.optionPrice}
                data-item-weight={option.optionWeight}
                data-item-taxable="false"
                data-item-url="/index.html"
              />
            )
          )
        ))
    );

    this.state.apparel.map(
      (apparel: ApparelProps, j: number) =>
        (items = items.concat(
          apparel.options.map(
            (
              option: {
                option: string;
                optionPrice: number;
                optionWeight: number;
              },
              i: number
            ) => (
              <button
                key={`apparel${i}${j}`}
                className="snipcart-add-item"
                data-item-id={apparel.title + " " + option.option}
                data-item-name={apparel.title + " " + option.option}
                data-item-weight={option.optionWeight}
                data-item-price={option.optionPrice}
                data-item-taxable="true"
                data-item-url="/index.html"
              />
            )
          )
        ))
    );

    return (
      <div
        className="App"
        style={{ visibility: this.state.loaded ? "visible" : "hidden" }}
      >
        {/* {items} */}
        <PromotionsBar promo={this.state.promotionalMessage} />
        <InfoBar
          facebook={this.state.facebook}
          instagram={this.state.instagram}
          phone={this.state.phone}
          email={this.state.email}
        />
        <Nav />
        <ScrollToTop>
          <Switch>
            <Route exact path="/snipcart" />
            <Route exact path="/about" component={About} />
            {this.state.provisions.length > 0 && (
              <Route
                exact
                path="/provisions"
                render={(props) => (
                  <Provisions {...props} provisions={this.state.provisions} />
                )}
              />
            )}
            {this.state.provisions.map(
              (provision: ProvisionProps, i: number) => (
                <Route
                  key={`provision${i}`}
                  exact
                  path={`/provisions/${makeLink(provision.title)}`}
                  render={(props) => <Provision {...props} {...provision} />}
                />
              )
            )}
            {this.state.apparel.length > 0 && (
              <Route
                exact
                path="/gear"
                render={(props) => (
                  <Apparel {...props} apparel={this.state.apparel} />
                )}
              />
            )}
            {this.state.apparel.map((apparel: ApparelProps, i: number) => (
              <Route
                key={`apparel${i}`}
                exact
                path={`/gear/${makeLink(apparel.title)}`}
                render={(props) => <ApparelItem {...props} {...apparel} />}
              />
            ))}
            {this.state.recipes.length > 0 && (
              <Route
                exact
                path="/recipes"
                render={(props) => (
                  <Recipes {...props} recipes={this.state.recipes} />
                )}
              />
            )}
            {this.state.recipes.map((recipe: RecipeProps, i: number) => (
              <Route
                key={`recipe${i}`}
                exact
                path={`/recipes/${makeLink(recipe.title)}`}
                render={(props) => <Recipe {...props} {...recipe} />}
              />
            ))}
            {this.state.news.length > 0 && (
              <Route
                exact
                path="/news"
                render={(props) => (
                  <News {...props} articles={this.state.news} />
                )}
              />
            )}
            {this.state.news.map((article: NewsProps, i: number) => (
              <Route
                key={`news${i}`}
                exact
                path={`/news/${makeLink(article.title)}`}
                render={(props) => <Article {...props} {...article} />}
              />
            ))}
            {this.state.locations.length > 0 &&
              this.state.locations.map((location: LocationProps, i: number) => (
                <Route
                  key={i}
                  exact
                  path={`/find/${makeLink(location.name)}`}
                  render={(props) => (
                    <Location
                      {...props}
                      name={location.name}
                      stores={location.stores}
                    />
                  )}
                />
              ))}
            <Route exact path="/find" component={Find} />
            <Route path="/" component={Home} />
          </Switch>
        </ScrollToTop>
        <Footer />
        <button
          className="snipcart-add-item"
          data-item-id="Caulfield Emblem Mug White Mug With Star Emblem"
          data-item-name="Caulfield Emblem Mug White Mug With Star Emblem"
          data-item-weight="340"
          data-item-price="8.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Caulfield Emblem T-Shirt Medium"
          data-item-name="Caulfield Emblem T-Shirt Medium"
          data-item-weight="196"
          data-item-price="20.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Caulfield Emblem T-Shirt Large"
          data-item-name="Caulfield Emblem T-Shirt Large"
          data-item-weight="196"
          data-item-price="20.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Caulfield Emblem T-Shirt XL"
          data-item-name="Caulfield Emblem T-Shirt XL"
          data-item-weight="196"
          data-item-price="20.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Caulfield Emblem T-Shirt XXL"
          data-item-name="Caulfield Emblem T-Shirt XXL"
          data-item-weight="196"
          data-item-price="20.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Women's Fitted T-Shirt XS"
          data-item-name="Women's Fitted T-Shirt XS"
          data-item-weight="196"
          data-item-price="20.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Women's Fitted T-Shirt Small"
          data-item-name="Women's Fitted T-Shirt Small"
          data-item-weight="196"
          data-item-price="20.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Women's Fitted T-Shirt Medium"
          data-item-name="Women's Fitted T-Shirt Medium"
          data-item-weight="196"
          data-item-price="20.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Women's Fitted T-Shirt Large"
          data-item-name="Women's Fitted T-Shirt Large"
          data-item-weight="196"
          data-item-price="20.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Women's Fitted T-Shirt XL"
          data-item-name="Women's Fitted T-Shirt XL"
          data-item-weight="196"
          data-item-price="20.00"
          data-item-url="/apparel"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="#56 Smokey Red Pepper Chipotle Sauce 6 ounce"
          data-item-name="#56 Smokey Red Pepper Chipotle Sauce 6 ounce"
          data-item-price="9.00"
          data-item-weight="332"
          data-item-url="/provisions"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="Variety Gift Pack - Four 6oz Bottles  6oz bottle variety 4-pack"
          data-item-name="Variety Gift Pack - Four 6oz Bottles  6oz bottle variety 4-pack"
          data-item-price="30.00"
          data-item-weight="1250"
          data-item-url="/provisions"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="#68 Szechuan Style Red Pepper Sauce 6 ounce"
          data-item-name="#68 Szechuan Style Red Pepper Sauce 6 ounce"
          data-item-price="9.00"
          data-item-weight="332"
          data-item-url="/provisions"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="#67 Minty Jalapeno Tomatillo Sauce 6oz"
          data-item-name="#67 Minty Jalapeno Tomatillo Sauce 6oz"
          data-item-price="9.00"
          data-item-weight="332"
          data-item-url="/provisions"
        ></button>
        <button
          className="snipcart-add-item"
          data-item-id="#60 Fiery Habanero Mustard Sauce 6 oz"
          data-item-name="#60 Fiery Habanero Mustard Sauce 6 oz"
          data-item-price="9.00"
          data-item-weight="332"
          data-item-url="/provisions"
        ></button>
      </div>
    );
  }
}

export default App;
